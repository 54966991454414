import { AutoForm, AutoFormInputField, Button } from "@9amhealth/shared";
import type { FC } from "react";
import React from "react";
import translate from "src/lib/translate";
import type { ChangeEmailFormValues } from "src/ui/components/ChangeEmail/ChangeEmail";
import Translate from "src/ui/components/Translate/Translate";
import { z } from "zod";
import FormError from "../FormError/FormError";
import styled from "@emotion/styled";

const Schema = z.object({
  email: z
    .string({ required_error: translate("error_validation_required") })
    .email({ message: translate("invalid_email") }),
  password: z.string({ required_error: translate("error_validation_required") })
});

export const PasswordInputWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

export const ButtonWrap = styled.button`
  position: absolute;
  right: 0;
  top: 4px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  cursor: pointer;
  pointer-events: all;
  background: none;
`;

const EmailPasswordForm: FC<{
  errorCode: string;
  onSubmit: (data: ChangeEmailFormValues) => Promise<unknown>;
  onChange?: () => void;
}> = (props) => {
  return (
    <AutoForm
      schema={Schema}
      onChange={props.onChange}
      onSubmit={(data) => void props.onSubmit(data as ChangeEmailFormValues)}
    >
      <AutoFormInputField
        name="email"
        label={translate("newEmailAddress")}
        autoComplete="username"
        required
        type="text"
        inputMode="email"
      />
      <nine-spacer s="sm"></nine-spacer>
      <AutoFormInputField
        isPasswordToggleEnabled
        name="password"
        label={translate("password")}
        type="password"
        required={true}
      />
      <nine-spacer></nine-spacer>

      <FormError code={props.errorCode} />
      <Button type="submit" theme="sunrise" style={{ margin: "auto" }}>
        <Translate msg={"confirm"} />
      </Button>
    </AutoForm>
  );
};

export default EmailPasswordForm;
